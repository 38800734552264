import { graphql, StaticQuery, Link } from "gatsby";
import React from "react";
import { mapEdgesToNodes } from "../../lib/helpers";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import Image from "../image/image";

export interface Employee {
  _id: string;
  image: {
    asset: any;
    alt: string;
  };
  order: number;
  role: string;
  name: string;
  linkedin: string;
  email: string;
}

import * as styles from "./team.module.scss";

const Team = () => (
  <StaticQuery
    query={graphql`
      query TeamQuery {
        team: allSanityTeam {
          edges {
            node {
              title
              employees {
                email
                linkedin
                name
                role
                image {
                  alt
                  asset {
                    _id
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const teamData = data && data.team && mapEdgesToNodes(data.team);

      const team: Employee[] = teamData[1].employees;
      const title = teamData[1].title;

      if (team[1]) {
        return (
          <div className={styles.wrapper}>
            <h2>{title}</h2>
            <ul className={styles.container}>
              {team.length > 0 &&
                team.map((employee, i) => (
                  <li
                    key={`employee-${i}`}
                    data-sal="slide-up"
                    data-sal-delay="50"
                    data-sal-easing="ease"
                    data-sal-duration="1000"
                  >
                    {employee.image && employee.image.asset && (
                      <Image
                        asset={employee.image.asset}
                        width={432}
                        height={453}
                        alt={employee.image.alt}
                        fit={"fill"}
                      ></Image>
                    )}
                    {employee.name && (
                      <div className={styles.employeeTextContainer}>
                        <span>{employee.name}</span>
                        <span>{employee.role}</span>
                        <div className={styles.contactContainer}>
                          {employee.linkedin && (
                            <Link to={employee.linkedin}>
                              <LinkedInIcon />
                            </Link>
                          )}
                          {employee.email && (
                            <a href={`mailto:${employee.email}`}>
                              <EmailIcon />
                            </a>
                          )}
                        </div>
                      </div>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        );
      } else {
        return null;
      }
    }}
  />
);

export default Team;
