import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Team from "../components/team/team";
import HeroSection from "../components/hero-section/hero-section";
import BlockContent from "../components/block-content";
import loadable from "@loadable/component";
import Icon from "../components/icon";
import Container from "../components/container/container";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

const HubspotFormComponent = loadable(() => import("../components/hubspot-form/hubspot-form"));

export const query = graphql`
  query AboutUsPageQuery {
    site: sanityAboutUsPage {
      subtitle
      title
      _rawDescription
      seo
      keywords
      id
      mainImage {
        asset {
          _id
        }
        alt
      }
    }
  }
`;

const AboutUsPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const imageUrl = imageUrlFor(buildImageObj(site.mainImage))
    .width(600)
    .width(600)
    .fit("fill")
    .url();

  console.log("imageUrl", imageUrl);

  return (
    <Layout>
      <SEO
        title={site.title}
        description={site.seo}
        keywords={site.keywords}
        image={imageUrlFor(buildImageObj(site.mainImage))
          .width(600)
          .height(600)
          .fit("fill")
          .url()}
      />
      <Container role="complementary">
        <HeroSection>
          <div>{site._rawDescription && <BlockContent blocks={site._rawDescription || []} />}</div>
          <div
            className="svg_responsive"
            data-sal="slide-up"
            data-sal-delay="350"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Icon symbol="ladyIcon"></Icon>
          </div>
        </HeroSection>
      </Container>
      <Container backgroundColor="white" role="complementary">
        <HubspotFormComponent
          portalId={5841642}
          formId="a73fb982-339b-452e-a1da-4c1fabb9c8ca"
          region="na1"
          title="Contact us"
          text="Please leave your details and we will contact you as soon as possible. In addition to your contact info, feel free to describe your current needs, and will start solving those needs before meeting you."
        ></HubspotFormComponent>
      </Container>
      <Container backgroundColor="white" role="complementary">
        <Team />
      </Container>
    </Layout>
  );
};

export default AboutUsPage;
